import * as React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../components/Seo';
import Layout from '../components/Layout/index';

// styles
const headingStyles = {
  marginTop: '5rem',
  marginBottom: 64,
  fontSize: '2.5rem',
  textAlign: 'center',
};

const paragraphStyles = {
  marginBottom: 48,
  textAlign: 'center',
};

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Seo />
      <h1 style={headingStyles}>Page not found</h1>
      <p style={paragraphStyles}>
        Sorry{' '}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{' '}
        we couldn’t find what you were looking for.
        <br />
        <br />
        <Link to="/">Go home</Link>.
      </p>
    </Layout>
  );
};

export default NotFoundPage;
